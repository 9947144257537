







































import Vue from 'vue';
import Component from 'vue-class-component';
import CumulativePortfolioChart from './components/CumulativePortfolioChart.vue';
import LiquidityTable from './components/LiquidityTable.vue';
import CumulativeRealizedChart from './components/CumulativeRealizedChart.vue';
import InfographicControl from './components/InfographicControl.vue';
import PerIntervalPortfolioChart from './components/PerIntervalPortfolioChart.vue';
import PerIntervalGainLossChart from './components/PerIntervalGainLossChart.vue';

@Component({
  components: {
    LiquidityTable,
    CumulativePortfolioChart,
    CumulativeRealizedChart,
    InfographicControl,
    PerIntervalPortfolioChart,
    PerIntervalGainLossChart,
  },
})

export default class Liquidity extends Vue {
}
