var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.companies,"expanded":_vm.expanded,"item-key":"name","show-expand":"","dense":"","hide-default-footer":"","loading":_vm.isLoading},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(header){return _c('td',{key:header.value,class:{
          'text-right': header.align && header.align === 'right',
        }},[(header.value !== 'data-table-expand')?_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")]):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v(" "+_vm._s(isExpanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down')+" ")])],1)],1)}),0)]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
        var headers = ref.headers;
return [_c('tr',{staticClass:"details-table-header"},[_c('th',{attrs:{"colspan":"7"}},[_vm._v(" "+_vm._s(item.name)+" Holdings ")]),_c('th',{staticClass:"text-right",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(item.holdingsSummary.plOpen)+" ")]),_c('th',{staticClass:"text-right",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(item.holdingsSummary.plDay)+" ")]),_c('th',{staticClass:"text-right",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(item.holdingsSummary.totalValue)+" ")]),_c('th',{staticClass:"text-right",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(item.holdingsSummary.freeValue)+" ")]),_c('th',{staticClass:"text-right",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(item.holdingsSummary.restrictedValue)+" ")]),_c('th')]),_vm._l((item.holdings),function(holding){return _c('tr',{key:holding.index},_vm._l((headers),function(header){return _c('td',{key:header.value,class:{
          'text-right': header.align && header.align === 'right',
        }},[_vm._v(" "+_vm._s(holding[header.value])+" ")])}),0)}),_c('tr',{staticClass:"details-table-header"},[_c('th',{attrs:{"colspan":_vm.fullWidthColSpan}},[_vm._v(" "+_vm._s(((item.name) + " Orders"))+" ")])]),_vm._l((item.orders),function(order){return _c('tr',{key:order.index},_vm._l((headers),function(header){return _c('td',{key:header.value,class:{
          'text-right': header.align && header.align === 'right',
        }},[_vm._v(" "+_vm._s(order[header.value])+" ")])}),0)}),_c('tr',{staticClass:"details-table-header"},[_c('th',{attrs:{"colspan":_vm.fullWidthColSpan}},[_vm._v(" "+_vm._s(((item.name) + " Projections"))+" ")])]),_c('tr',[_c('td',{staticClass:"px-0 py-3",attrs:{"colspan":_vm.fullWidthColSpan}},[_c('v-row',{staticClass:"px-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('holding-market-price-chart')],1)],1),_c('v-row',{staticClass:"px-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-lg-one-fifth"},[_c('holding-position-value-chart')],1),_c('v-col',{staticClass:"col-lg-one-fifth"},[_c('holding-proceeds-chart')],1),_c('v-col',{staticClass:"col-lg-one-fifth"},[_c('holding-unrealized-chart')],1),_c('v-col',{staticClass:"col-lg-one-fifth"},[_c('holding-realized-chart')],1),_c('v-col',{staticClass:"col-lg-one-fifth"},[_c('holding-free-restricted-chart')],1)],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }