import { LiquidityInterval } from '@/types/liquidity';
import { RealizedPerIntervalResponse } from '@/types/responses';
import { randomIntFromInterval } from '@/utils/utils';
import moment from 'moment';

const generateDailyData = () => {
  const startDate = moment().subtract('3', 'years').startOf('day');
  const endDate = moment().add('3', 'years').startOf('day');
  const today = moment().startOf('day');

  const data = [];
  let lastValue = 0;
  let lastMaximum: number | null = 0;
  let lastMinimum: number | null = 0;
  while (startDate.isSameOrBefore(endDate)) {
    const isPast = startDate.isBefore(today);
    const isToday = startDate.isSame(today);

    if (isPast) {
      lastValue = randomIntFromInterval(-10, 30);
      lastMaximum = null;
      lastMinimum = null;
    } else if (isToday) {
      lastValue = randomIntFromInterval(-20, 20);
      lastMaximum = lastValue + randomIntFromInterval(0, 4);
      lastMinimum = lastValue - randomIntFromInterval(0, 4);
    } else {
      lastValue = 0;
      lastMaximum = 0;
      lastMinimum = 0;
    }

    data.push({
      start: startDate.format('YYYY-MM-DD HH:mm:ss'),
      end: startDate.clone().add('1', 'day').subtract('1', 'second').format('YYYY-M-DD HH:mm:ss'),
      value: lastValue,
      maximum: lastMaximum,
      minimum: lastMinimum,
    });

    startDate.add('1', 'day');
  }

  return data;
};

const dailyData = generateDailyData();

const getRealizedPerIntervalData = (interval: LiquidityInterval): RealizedPerIntervalResponse => {
  if (interval === 'day') {
    return {
      data: {
        realizedPerInterval: dailyData,
      },
    };
  }

  let startDate = moment(dailyData[0].start, 'YYYY-MM-DD HH:mm:ss');
  let endDate = startDate.clone().add('1', interval).subtract('1', 'second');

  const startObject: {
    [key: string]: {
      start: string;
      end: string;
      value: number;
      maximum: number;
      minimum: number;
    };
  } = {};
  // group data by interval
  // Note: dailyData must be ordered by start date for this to work
  const groupedData = dailyData.reduce((acc, row) => {
    if (!moment(row.start, 'YYYY-MM-DD HH:mm:ss').isBetween(startDate, endDate, null, '[]')) {
      // move to next interval
      startDate = startDate.clone().add('1', interval);
      endDate = startDate.clone().add('1', interval).subtract('1', 'second');
    }

    const startDateKey = startDate.clone().format('YYYY-MM-DD HH:mm:ss');

    if (typeof acc[startDateKey] !== 'undefined') {
      acc[startDateKey].value += row.value;
      acc[startDateKey].maximum += row.maximum === null ? 0 : row.maximum;
      acc[startDateKey].minimum += row.minimum === null ? 0 : row.minimum;
    } else {
      acc[startDateKey] = {
        start: startDate.clone().format('YYYY-M-DD HH:mm:ss'),
        end: endDate.clone().format('YYYY-M-DD HH:mm:ss'),
        value: row.value,
        maximum: row.maximum === null ? 0 : row.maximum,
        minimum: row.minimum === null ? 0 : row.minimum,
      };
    }

    return acc;
  }, startObject);

  return {
    data: {
      realizedPerInterval: Object.values(groupedData).map((row) => ({
        ...row,
        maximum: row.maximum === 0 ? null : row.maximum,
        minimum: row.minimum === 0 ? null : row.minimum,
      })),
    },
  };
};

export default getRealizedPerIntervalData;
