








import Vue from 'vue';
import Component from 'vue-class-component';
import BaseAppBar from './components/BaseAppBar.vue';
import BaseNavigationDrawer from './components/BaseNavigationDrawer.vue';

@Component({
  components: {
    BaseAppBar,
    BaseNavigationDrawer,
  },
})
export default class Dashboard extends Vue {
}
