import { LiquidityTableResponse } from '@/types/responses';

const liquidityTableResponse: LiquidityTableResponse = {
  data: {
    companies: [
      {
        name: 'PTIX',
        plOpen: 12889,
        plDay: 976,
        totalValue: 131454,
        freeValue: 131454,
        restrictedValue: null,
        live: false,
        holdings: [],
        orders: [],
        projections: null,
      },
      {
        name: 'QLGN',
        plOpen: 942,
        plDay: -2355,
        totalValue: 12555,
        freeValue: 12555,
        restrictedValue: null,
        live: false,
        holdings: [],
        orders: [],
        projections: null,
      },
      {
        name: 'SRAX',
        plOpen: 210432.52,
        plDay: 10342.43,
        totalValue: 88320,
        freeValue: 88320,
        restrictedValue: null,
        live: true,
        holdings: [
          {
            quantity: 12100,
            free: 12100,
            restricted: 0,
            initialPrice: 4,
            marketPrice: 5.35,
            marketChangeDay: 0.37,
            plOpen: 16355,
            plDay: 4477,
            totalValue: 64735,
            freeValue: 64735,
            restrictedValue: null,
          },
          {
            quantity: 4400,
            free: 0,
            restricted: 4400,
            initialPrice: 5,
            marketPrice: 5.35,
            marketChangeDay: 0.37,
            plOpen: 1540,
            plDay: 1628,
            totalValue: 23540,
            freeValue: null,
            restrictedValue: 23540,
          },
        ],
        orders: [
          {
            name: '100 18 Feb 2022 5.00 Put',
            quantity: 120,
            initialPrice: 0.45,
            marketPrice: 0.75,
            marketChangeDay: 0.025,
            plOpen: -3600,
            plDay: -300,
            totalValue: 5400,
            freeValue: 5400,
            restrictedValue: null,
          },
        ],
        projections: null,
      },
    ],
  },
};

export default liquidityTableResponse;
