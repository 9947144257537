import { LiquidityInterval } from '@/types/liquidity';

export const getPlotLineText = (interval: LiquidityInterval): string => {
  switch (interval) {
    case 'day':
      return 'Today';
    case 'week':
      return 'This week';
    case 'month':
      return 'This month';
    case 'quarter':
      return 'This quarter';
    default:
      return '';
  }
};
