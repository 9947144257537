import {
  Module, VuexModule, Mutation,
} from 'vuex-module-decorators';
import moment from 'moment';
import '@/store/config/moduleConfig';
import { LiquidityGroupBy, LiquidityInterval, LiquidityWarrant } from '@/types/liquidity';

@Module({
  namespaced: true,
  name: 'liquidity',
})
export default class LiquidityModule extends VuexModule {
  intervalFilter: LiquidityInterval = 'week';

  symbolFilter: '' = '';

  groupByFilter: LiquidityGroupBy = 'None';

  dateRangeFilter: [string, string] = [
    moment().subtract('1', 'years')
      .startOf('day')
      .format('YYYY-MM-DD')
      .toString(),
    moment().add('6', 'months')
      .startOf('day')
      .format('YYYY-MM-DD')
      .toString(),
  ]

  warrantFilter: LiquidityWarrant = 'Intrinsic Value'

  @Mutation
  setIntervalFilter(interval: LiquidityInterval): void {
    this.intervalFilter = interval;
  }

  @Mutation
  setSymbolFilter(symbol: ''): void {
    this.symbolFilter = symbol;
  }

  @Mutation
  setGroupByFilter(group: LiquidityGroupBy): void {
    this.groupByFilter = group;
  }

  @Mutation
  setDateRangeFilter(dateRange: [string, string]): void {
    this.dateRangeFilter = dateRange;
  }

  @Mutation
  setWarrantFilter(warrant: LiquidityWarrant): void {
    this.warrantFilter = warrant;
  }
}
