











































































































































import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import LiquidityModule from '@/store/modules/liquidity';
import Component from 'vue-class-component';
import { formatDate } from '@/utils/utils';
import { LiquidityGroupBy, LiquidityInterval, LiquidityWarrant } from '@/types/liquidity';

@Component
export default class InfographicControl extends Vue {
  liquidityModule = getModule(LiquidityModule, this.$store);

  setInterval(interval: LiquidityInterval): void {
    this.liquidityModule.setIntervalFilter(interval);
  }

  setSymbol(symbol: ''): void {
    this.liquidityModule.setSymbolFilter(symbol);
  }

  setGroupBy(group: LiquidityGroupBy): void {
    this.liquidityModule.setGroupByFilter(group);
  }

  setWarrant(warrant: LiquidityWarrant): void {
    this.liquidityModule.setWarrantFilter(warrant);
  }

  get intervalFilter(): string {
    return this.liquidityModule.intervalFilter;
  }

  get symbolFilter(): string {
    return this.liquidityModule.symbolFilter;
  }

  get groupByFilter(): string {
    return this.liquidityModule.groupByFilter;
  }

  get dateRangeFilter(): [string, string] {
    return this.liquidityModule.dateRangeFilter;
  }

  get warrantFilter(): string {
    return this.liquidityModule.warrantFilter;
  }

  configurationMenuOpen = false;

  get formattedDateRange(): string {
    return `${formatDate(this.dateRange[0])} - ${formatDate(this.dateRange[1])}`;
  }

  searchString = ''

  groups: LiquidityGroupBy[] = [
    'None',
    'Exchange',
    'Sector',
    'Market Cap',
  ];

  intervals: {
    text: string;
    value: LiquidityInterval,
  }[] = [
    {
      text: 'Day',
      value: 'day',
    },
    {
      text: 'Week',
      value: 'week',
    },
    {
      text: 'Month',
      value: 'month',
    }, {
      text: 'Quarter',
      value: 'quarter',
    },
  ];

  warrants: LiquidityWarrant[] = [
    'Intrinsic Value',
    'Black Scholes',
  ];

  dateRange: [string, string] = [
    this.liquidityModule.dateRangeFilter[0],
    this.liquidityModule.dateRangeFilter[1],
  ];

  onChangeInterval(interval: LiquidityInterval): void {
    this.liquidityModule.setIntervalFilter(interval);
  }

  onChangeSymbol(symbol: ''): void {
    this.liquidityModule.setSymbolFilter(symbol);
  }

  onChangeGroup(group: LiquidityGroupBy): void {
    this.liquidityModule.setGroupByFilter(group);
  }

  onChangeDateRange(dates: [string] | [string, string]): void {
    if (dates.length === 2) {
      this.liquidityModule.setDateRangeFilter(dates);
    }
  }

  onChangeWarrant(warrant: LiquidityWarrant): void {
    this.liquidityModule.setWarrantFilter(warrant);
  }
}
