export const CHART_BACKGROUND_DARK = '#1e1e1e';

export const CHART_SERIES_COLOR_BLUE = '#5598df';
export const CHART_SERIES_COLOR_ORANGE = '#f38441';
export const CHART_SERIES_COLOR_RED = '#f06d65';
export const CHART_SERIES_COLOR_LIGHT_GREY = '#a3acb7';
export const CHART_SERIES_COLOR_PURPLE = '#B67BBD';
export const CHART_SERIES_COLOR_CYAN = '#6CF5B7';
export const CHART_SERIES_COLOR_YELLOW = '#F5D24D';

export const CHART_PLOT_LINE_COLOR = '#56c2b8';
