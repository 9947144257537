















import {
  Options,
  XAxisOptions,
} from 'highcharts';
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import { getModule } from 'vuex-module-decorators';
import { Watch } from 'vue-property-decorator';
import LiquidityModule from '@/store/modules/liquidity';
import realizedPerIntervalResponse from '@/api/mockData/realizedPerIntervalResponse';
import unrealizedPerIntervalResponse from '@/api/mockData/unrealizedPerIntervalResponse';
import { LiquidityGroupBy, LiquidityInterval } from '@/types/liquidity';
import {
  CHART_PLOT_LINE_COLOR,
  CHART_SERIES_COLOR_BLUE,
  CHART_SERIES_COLOR_LIGHT_GREY,
  CHART_SERIES_COLOR_ORANGE,
} from '@/constants/chart';
import { getPlotLineText } from '@/views/Liquidity/utils';
import { currentPointFormatter, currentYAxisLabelFormatter } from '@/utils/highcharts-utils';

const SERIES_NAME_REALIZED_COLUMN = 'Realized Gain/(Loss)';
const SERIES_NAME_REALIZED_MIN_MAX = 'Realized Min/Max';
const SERIES_NAME_UNREALIZED_COLUMN = 'Unrealized Gain/(Loss)';
const SERIES_NAME_UNREALIZED_MIN_MAX = 'Unrealized Min/Max';

@Component
export default class PerIntervalGainLossChart extends Vue {
  chartOptions: Options = {
    chart: {
      height: '300px',
      zoomType: 'x',
    },
    series: [],
    xAxis: {
      type: 'datetime',
      plotLines: [],
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        formatter: currentYAxisLabelFormatter,
      },
    },
    title: {
      text: 'Available Potential Value',
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      pointFormatter: currentPointFormatter,
    },
  };

  isLoading = false;

  liquidityModule = getModule(LiquidityModule, this.$store);

  @Watch('liquidityInterval', {
    immediate: true,
  })
  onIntervalChange(newInterval: LiquidityInterval): void {
    this.loadData(newInterval, this.liquidityGroupBy, this.liquidityDateRange);
  }

  @Watch('liquidityGroupBy')
  onGroupByChange(groupBy: LiquidityGroupBy): void {
    this.loadData(this.liquidityInterval, groupBy, this.liquidityDateRange);
  }

  @Watch('liquidityDateRange')
  onDateRangeChange(dateRange: [string, string]): void {
    this.loadData(this.liquidityInterval, this.liquidityGroupBy, dateRange);
  }

  get liquidityInterval(): LiquidityInterval {
    return this.liquidityModule.intervalFilter;
  }

  get liquidityGroupBy(): LiquidityGroupBy {
    return this.liquidityModule.groupByFilter;
  }

  get liquidityDateRange(): [string, string] {
    return this.liquidityModule.dateRangeFilter;
  }

  async loadData(
    interval: LiquidityInterval,
    groupBy: LiquidityGroupBy,
    dateRange: [string, string],
  ): Promise<void> {
    try {
      this.isLoading = true;

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const realizedStartObject: {
        minMaxData: [number, number | null, number | null][];
        columnData: [number, number][];
        todaysUnixtime: number | null;
      } = {
        minMaxData: [],
        columnData: [],
        todaysUnixtime: null,
      };
      const realizedData = realizedPerIntervalResponse(interval).data.realizedPerInterval
        .reduce((acc, row) => {
          const unixtime = moment.utc(row.start, 'YYYY-MM-DD HH:mm:ss').valueOf();
          acc.columnData.push([unixtime, row.value]);

          const rowContainsToday = moment().isBetween(
            moment(row.start, 'YYYY-MM-DD HH:mm:ss'),
            moment(row.end, 'YYYY-MM-DD HH:mm:ss'),
            null,
            '[]',
          );

          if (rowContainsToday) {
            acc.todaysUnixtime = unixtime;
          }

          // don't include min/max data when interval !== 'day'
          // this is because we may only have min/max data for 4/7 days
          // of the week, in which case the mix/max graph will be less than expected
          if (!rowContainsToday
            || interval === 'day'
          ) {
            acc.minMaxData.push([unixtime, row.minimum, row.maximum]);
          }

          return acc;
        }, realizedStartObject);

      if (this.chartOptions && this.chartOptions.title && this.chartOptions.title.text) {
        const chartTitle = `Available Potential Value Per ${interval
          ? interval[0].toUpperCase() + interval.slice(1)
          : ''}`;

        this.chartOptions.title.text = chartTitle;
      }

      if (realizedData.todaysUnixtime !== null
        && this.chartOptions
        && this.chartOptions.xAxis
        && (this.chartOptions.xAxis as XAxisOptions).plotLines
      ) {
        const plotLineText = getPlotLineText(interval);
        (this.chartOptions.xAxis as XAxisOptions).plotLines = [{
          color: CHART_PLOT_LINE_COLOR,
          width: 2,
          value: realizedData.todaysUnixtime,
          dashStyle: 'ShortDash',
          label: {
            text: plotLineText,
            style: {
              color: CHART_PLOT_LINE_COLOR,
            },
            rotation: 0,
          },
        }];
      }

      const unrealizedStartObject: {
        minMaxData: [number, number | null, number | null][];
        columnData: [number, number][];
      } = {
        minMaxData: [],
        columnData: [],
      };
      const unrealizedData = unrealizedPerIntervalResponse(interval).data.unrealizedPerInterval
        .reduce((acc, row) => {
          const unixtime = moment.utc(row.start, 'YYYY-MM-DD HH:mm:ss').valueOf();
          acc.columnData.push([unixtime, row.value]);

          const rowContainsToday = moment().isBetween(
            moment(row.start, 'YYYY-MM-DD HH:mm:ss'),
            moment(row.end, 'YYYY-MM-DD HH:mm:ss'),
            null,
            '[]',
          );

          // don't include min/max data when interval !== 'day'
          // this is because we may only have min/max data for 4/7 days
          // of the week, in which case the mix/max graph will be less than expected
          if (!rowContainsToday
            || interval === 'day'
          ) {
            acc.minMaxData.push([unixtime, row.minimum, row.maximum]);
          }
          return acc;
        }, unrealizedStartObject);

      const realizedDataFiltered = realizedData.columnData.filter(([date]) => (
        moment(date).isBetween(
          moment(dateRange[0], 'YYYY-MM-DD HH:mm:ss'),
          moment(dateRange[1], 'YYYY-MM-DD HH:mm:ss'),
        )));
      const realizedMinMaxDataFiltered = realizedData.minMaxData.filter(([date]) => (
        moment(date).isBetween(
          moment(dateRange[0], 'YYYY-MM-DD HH:mm:ss'),
          moment(dateRange[1], 'YYYY-MM-DD HH:mm:ss'),
        )));
      const unrealizedDataFiltered = unrealizedData.columnData.filter(([date]) => (
        moment(date).isBetween(
          moment(dateRange[0], 'YYYY-MM-DD HH:mm:ss'),
          moment(dateRange[1], 'YYYY-MM-DD HH:mm:ss'),
        )));
      const unrealizedMinMaxDataFiltered = unrealizedData.minMaxData.filter(([date]) => (
        moment(date).isBetween(
          moment(dateRange[0], 'YYYY-MM-DD HH:mm:ss'),
          moment(dateRange[1], 'YYYY-MM-DD HH:mm:ss'),
        )));

      switch (groupBy) {
        case 'None':
          if (this.chartOptions && this.chartOptions.series) {
            this.chartOptions.series = [
              // realized
              {
                type: 'column',
                data: realizedDataFiltered,
                name: SERIES_NAME_REALIZED_COLUMN,
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'columnrange',
                data: realizedMinMaxDataFiltered,
                name: SERIES_NAME_REALIZED_MIN_MAX,
                color: CHART_SERIES_COLOR_LIGHT_GREY,
                borderColor: CHART_SERIES_COLOR_LIGHT_GREY,
                maxPointWidth: 1,
              },
              // unrealized
              {
                type: 'column',
                data: unrealizedDataFiltered,
                name: SERIES_NAME_UNREALIZED_COLUMN,
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
              {
                type: 'columnrange',
                data: unrealizedMinMaxDataFiltered,
                name: SERIES_NAME_UNREALIZED_MIN_MAX,
                color: CHART_SERIES_COLOR_LIGHT_GREY,
                borderColor: CHART_SERIES_COLOR_LIGHT_GREY,
                maxPointWidth: 1,
              },
            ];
          }

          if (this.chartOptions && this.chartOptions.legend) {
            this.chartOptions.legend.enabled = false;
          }
          break;
        case 'Exchange':
          if (this.chartOptions && this.chartOptions.series) {
            this.chartOptions.series = [
              {
                type: 'column',
                data: realizedDataFiltered.map(([date, value]) => ([date, value * 1])),
                name: 'NYSE Realized',
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'column',
                data: unrealizedDataFiltered.map(([date, value]) => ([date, value * 1])),
                name: 'NYSE Unrealized',
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
              {
                type: 'column',
                data: realizedDataFiltered.map(([date, value]) => ([date, value * 0])),
                name: 'NASDAQ Realized',
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'column',
                data: unrealizedDataFiltered.map(([date, value]) => ([date, value * 0])),
                name: 'NASDAQ Unrealized',
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
              {
                type: 'column',
                data: realizedDataFiltered.map(([date, value]) => ([date, value * 0])),
                name: 'OTC Realized',
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'column',
                data: unrealizedDataFiltered.map(([date, value]) => ([date, value * 0])),
                name: 'OTC Unrealized',
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
            ];
          }

          if (this.chartOptions && this.chartOptions.legend) {
            this.chartOptions.legend.enabled = true;
          }
          break;
        case 'Sector':
          if (this.chartOptions && this.chartOptions.series) {
            this.chartOptions.series = [
              {
                type: 'column',
                data: realizedDataFiltered.map(([date, value]) => ([date, value * 0.37])),
                name: 'Information Technology Realized',
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'column',
                data: unrealizedDataFiltered.map(([date, value]) => ([date, value * 0.37])),
                name: 'Information Technology Unrealized',
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
              {
                type: 'column',
                data: realizedDataFiltered.map(([date, value]) => ([date, value * 0.63])),
                name: 'Healthcare Realized',
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'column',
                data: unrealizedDataFiltered.map(([date, value]) => ([date, value * 0.63])),
                name: 'Healthcare Unrealized',
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
            ];
          }

          if (this.chartOptions && this.chartOptions.legend) {
            this.chartOptions.legend.enabled = true;
          }
          break;
        case 'Market Cap':
          if (this.chartOptions && this.chartOptions.series) {
            this.chartOptions.series = [
              {
                type: 'column',
                data: realizedDataFiltered.map(([date, value]) => ([date, value * 0.3])),
                name: 'Micro Realized',
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'column',
                data: unrealizedDataFiltered.map(([date, value]) => ([date, value * 0.3])),
                name: 'Micro Unrealized',
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
              {
                type: 'column',
                data: realizedDataFiltered.map(([date, value]) => ([date, value * 0.7])),
                name: 'Nano Realized',
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'column',
                data: unrealizedDataFiltered.map(([date, value]) => ([date, value * 0.7])),
                name: 'Nano Unrealized',
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
            ];
          }

          if (this.chartOptions && this.chartOptions.legend) {
            this.chartOptions.legend.enabled = true;
          }
          break;
        default:
          // do nothing
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      this.isLoading = false;
    }
  }
}
