import { HoldingMarketPriceResponse } from '@/types/responses';
import moment from 'moment';
import MarketData from './csv/srax_holding_market_price_and_order_data.csv';
import OrderData from './csv/srax_order_data.csv';

const holdingMarketPriceResponse: HoldingMarketPriceResponse = {
  data: {
    holdingMarketPrice: MarketData.map((row: {
      date: string;
      closingPrice1: number;
      tradingVolume1: number;
      selfVolume1: number;
    }) => {
      const orderForDay = OrderData.find((order: {
        orderId: string;
        symbol: string;
        volume: string;
        startDate: string;
        endDate: string;
      }) => moment(row.date).isBetween(
        moment(order.startDate, 'YYYY-MM-DD'),
        moment(order.endDate, 'YYYY-MM-DD'),
        null,
        '[]',
      ));

      const order = orderForDay || null;

      return {
        ...row,
        order,
      };
    }),
  },
};

export default holdingMarketPriceResponse;
