





































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import LiquidityTableResponse from '@/api/mockData/liquidityTableResponse';
import { formatMoney } from '@/utils/utils';
import HoldingPositionValueChart from './HoldingPositionValueChart.vue';
import HoldingProceedsChart from './HoldingProceedsChart.vue';
import HoldingUnrealizedChart from './HoldingUnrealizedChart.vue';
import HoldingRealizedChart from './HoldingRealizedChart.vue';
import HoldingFreeRestrictedChart from './HoldingFreeRestrictedChart.vue';
import HoldingMarketPriceChart from './HoldingMarketPriceChart.vue';

@Component({
  components: {
    HoldingPositionValueChart,
    HoldingProceedsChart,
    HoldingUnrealizedChart,
    HoldingRealizedChart,
    HoldingFreeRestrictedChart,
    HoldingMarketPriceChart,
  },
})
export default class LiquidityTable extends Vue {
  expanded = []

  headers = [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    {
      text: 'Qty',
      value: 'quantity',
      align: 'right',
    },
    {
      text: 'Free',
      value: 'free',
      align: 'right',
    },
    {
      text: 'Restricted',
      value: 'restricted',
      align: 'right',
    },
    {
      text: 'Initial Price',
      value: 'initialPrice',
      align: 'right',
    },
    {
      text: 'Market Price',
      value: 'marketPrice',
      align: 'right',
    },
    {
      text: 'Market Change Day',
      value: 'marketChangeDay',
      align: 'right',
    },
    {
      text: 'P/L Open',
      value: 'plOpen',
      align: 'right',
    },
    {
      text: 'P/L Day',
      value: 'plDay',
      align: 'right',
    },
    {
      text: 'Total Value',
      value: 'totalValue',
      align: 'right',
    },
    {
      text: 'Free Value',
      value: 'freeValue',
      align: 'right',
    },
    {
      text: 'Restricted Value',
      value: 'restrictedValue',
      align: 'right',
    },
    {
      text: '',
      value: 'data-table-expand',
      align: 'right',
    },
  ];

  isLoading = false;

  get fullWidthColSpan(): number {
    return this.headers.length + 1;
  }

  companies: unknown = [];

  async loadData(): Promise<void> {
    try {
      this.isLoading = true;

      await new Promise((resolve) => setTimeout(resolve, 1000));

      this.companies = LiquidityTableResponse.data.companies.map((company) => ({
        ...company,
        plOpen: formatMoney(company.plOpen, 2),
        plDay: formatMoney(company.plDay, 2),
        totalValue: formatMoney(company.totalValue, 2),
        freeValue: formatMoney(company.freeValue, 2),
        restrictedValue: company.restrictedValue ? formatMoney(company.restrictedValue, 2) : null,

        holdingsSummary: company.holdings
          ? {
            plOpen: formatMoney(company.holdings
              .reduce((a, b) => a + (b.plOpen ? b.plOpen : 0), 0), 2),
            plDay: formatMoney(company.holdings
              .reduce((a, b) => a + (b.plDay ? b.plDay : 0), 0), 2),
            totalValue: formatMoney(company.holdings.reduce(
              (a, b) => a + (b.totalValue ? b.totalValue : 0), 0,
            ), 2),
            freeValue: formatMoney(company.holdings.reduce(
              (a, b) => a + (b.freeValue ? b.freeValue : 0), 0,
            ), 2),
            restrictedValue: formatMoney(company.holdings.reduce(
              (a, b) => a + (b.restrictedValue ? b.restrictedValue : 0), 0,
            ), 2),
          }
          : null,

        holdings: company.holdings
          ? company.holdings.map((holding) => ({
            ...holding,
            plOpen: formatMoney(company.plOpen, 2),
            plDay: formatMoney(company.plDay, 2),
            totalValue: formatMoney(company.totalValue, 2),
            freeValue: formatMoney(company.freeValue, 2),
            restrictedValue: company.restrictedValue
              ? formatMoney(company.restrictedValue, 2)
              : null,
            initialPrice: formatMoney(holding.initialPrice, 2),
            marketPrice: formatMoney(holding.marketPrice, 2),
            marketChangeDay: formatMoney(holding.marketChangeDay, 2),
          }))
          : null,

        orders: company.orders
          ? company.orders.map((order) => ({
            ...order,
            plOpen: formatMoney(company.plOpen, 2),
            plDay: formatMoney(company.plDay, 2),
            totalValue: formatMoney(company.totalValue, 2),
            freeValue: formatMoney(company.freeValue, 2),
            restrictedValue: company.restrictedValue
              ? formatMoney(company.restrictedValue, 2)
              : null,
          }))
          : null,

      }));
    } catch (error) {
      console.error('error', error);
    } finally {
      this.isLoading = false;
    }
  }

  mounted(): void {
    this.loadData();
  }
}
