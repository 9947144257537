























import {
  // ROUTE_NAME_DEALS,
  ROUTE_LIQUIDITY,
} from '@/router/routes';
import Vue from 'vue';
import { RawLocation } from 'vue-router';
import Component from 'vue-class-component';

const routes: {
  to: RawLocation,
  text: string
}[] = [
  // {
  //   to: {
  //     name: ROUTE_NAME_DEALS,
  //   },
  //   text: 'Deals',
  // },
  {
    to: {
      name: ROUTE_LIQUIDITY,
    },
    text: 'Liquidity',
  },
];

@Component
export default class BaseNavigationDrawer extends Vue {
  routes = routes;
}
