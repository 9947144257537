





































import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import MyInfoModule from '@/store/modules/myInfo';
import { ROUTE_NAME_LOGIN } from '@/router/routes';

@Component
export default class BaseAppBar extends Vue {
  myInfoModule = getModule(MyInfoModule, this.$store);

  get username(): string | null {
    return this.myInfoModule.username;
  }

  onLogout(): void {
    this.myInfoModule.logout();
    this.$router.push({ name: ROUTE_NAME_LOGIN });
  }
}
