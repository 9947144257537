
















import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Watch } from 'vue-property-decorator';
import LiquidityModule from '@/store/modules/liquidity';
import cumulativeRealizedResponse from '@/api/mockData/cumulativeRealizedResponse';
import moment from 'moment';
import { getPlotLineText } from '@/views/Liquidity/utils';
import { LiquidityGroupBy, LiquidityInterval } from '@/types/liquidity';
import CumulativeChart from './CumulativeChart.vue';

@Component({
  components: { CumulativeChart },
})
export default class CumulativeRealizedChart extends Vue {
  $refs!: {
    chart: CumulativeChart,
  }

  isLoading = false;

  liquidityModule = getModule(LiquidityModule, this.$store);

  @Watch('liquidityInterval', {
    immediate: true,
  })
  onIntervalChange(newInterval: LiquidityInterval): void {
    this.loadData(newInterval, this.liquidityGroupBy, this.liquidityDateRange);
  }

  @Watch('liquidityGroupBy')
  onGroupByChange(groupBy: LiquidityGroupBy): void {
    this.loadData(this.liquidityInterval, groupBy, this.liquidityDateRange);
  }

  @Watch('liquidityDateRange')
  onDateRangeChange(dateRange: [string, string]): void {
    this.loadData(this.liquidityInterval, this.liquidityGroupBy, dateRange);
  }

  get liquidityInterval(): LiquidityInterval {
    return this.liquidityModule.intervalFilter;
  }

  get liquidityGroupBy(): LiquidityGroupBy {
    return this.liquidityModule.groupByFilter;
  }

  get liquidityDateRange(): [string, string] {
    return this.liquidityModule.dateRangeFilter;
  }

  async loadData(
    interval: LiquidityInterval,
    groupBy: LiquidityGroupBy,
    dateRange: [string, string],
  ): Promise<void> {
    try {
      this.isLoading = true;

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const startObject: {
        minMaxData: [number, number | null, number | null][];
        realizedData: [number, number][];
        todaysUnixtime: number | null;
      } = {
        minMaxData: [],
        realizedData: [],
        todaysUnixtime: null,
      };
      const {
        minMaxData,
        realizedData,
        todaysUnixtime,
      } = cumulativeRealizedResponse(interval).data.realized.reduce((acc, row) => {
        const unixtime = moment.utc(row.start, 'YYYY-MM-DD HH:mm:ss').valueOf();
        acc.minMaxData.push([unixtime, row.minimum, row.maximum]);
        acc.realizedData.push([unixtime, row.value]);

        if (moment().isBetween(
          moment(row.start, 'YYYY-MM-DD HH:mm:ss'),
          moment(row.end, 'YYYY-MM-DD HH:mm:ss'),
          null,
          '[]',
        )) {
          acc.todaysUnixtime = unixtime;
        }

        return acc;
      }, startObject);

      const realizedDataFiltered = realizedData.filter(([date]) => (
        moment(date).isBetween(
          moment(dateRange[0], 'YYYY-MM-DD HH:mm:ss'),
          moment(dateRange[1], 'YYYY-MM-DD HH:mm:ss'),
        )));
      const minMaxDataFiltered = minMaxData.filter(([date]) => (
        moment(date).isBetween(
          moment(dateRange[0], 'YYYY-MM-DD HH:mm:ss'),
          moment(dateRange[1], 'YYYY-MM-DD HH:mm:ss'),
        )));

      switch (groupBy) {
        case 'None':
          this.$refs.chart.setSingleLineData(realizedDataFiltered, minMaxDataFiltered);
          this.$refs.chart.setLegend(false);
          break;
        case 'Exchange':
          this.$refs.chart.setMultiLineData(
            {
              data: realizedDataFiltered.map(([date, value]) => ([date, value * 1])),
              name: 'NYSE',
            },
            {
              data: realizedDataFiltered.map(([date, value]) => ([date, value * 0])),
              name: 'NASDAQ',
            },
            {
              data: realizedDataFiltered.map(([date, value]) => ([date, value * 0])),
              name: 'OTC',
            },
          );
          this.$refs.chart.setLegend(true);
          break;
        case 'Sector':
          this.$refs.chart.setMultiLineData(
            {
              data: realizedDataFiltered.map(([date, value]) => ([date, value * 0.37])),
              name: 'Information Technology',
            },
            {
              data: realizedDataFiltered.map(([date, value]) => ([date, value * 0.63])),
              name: 'Healthcare',
            },
          );
          this.$refs.chart.setLegend(true);
          break;
        case 'Market Cap':
          this.$refs.chart.setMultiLineData(
            {
              data: realizedDataFiltered.map(([date, value]) => ([date, value * 0.30])),
              name: 'Micro',
            },
            {
              data: realizedDataFiltered.map(([date, value]) => ([date, value * 0.70])),
              name: 'Nano',
            },
          );
          this.$refs.chart.setLegend(true);
          break;
        default:
          // do nothing
      }

      if (todaysUnixtime !== null) {
        const plotLineText = getPlotLineText(interval);
        this.$refs.chart.setPlotLine(todaysUnixtime, plotLineText);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      this.isLoading = false;
    }
  }
}
