export const login = async (email: string, password: string): Promise<{
  token: string;
  name: string;
}> => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  if (email === '' || password === '') {
    throw new Error('Invalid Credentials');
  }

  return {
    token: 'j.w.t',
    name: 'Lutz Lai',
  };
};
