














import {
  Options,
  numberFormat,
  dateFormat,
  XAxisOptions,
} from 'highcharts';
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import { getModule } from 'vuex-module-decorators';
import { Watch } from 'vue-property-decorator';
import LiquidityModule from '@/store/modules/liquidity';
import { LiquidityGroupBy, LiquidityInterval } from '@/types/liquidity';
import portfolioPerIntervalResponse from '@/api/mockData/portfolioPerIntervalResponse';
import {
  CHART_PLOT_LINE_COLOR,
  CHART_SERIES_COLOR_BLUE,
  CHART_SERIES_COLOR_LIGHT_GREY,
  CHART_SERIES_COLOR_ORANGE,
  CHART_SERIES_COLOR_RED,
} from '@/constants/chart';
import { getPlotLineText } from '@/views/Liquidity/utils';
import { currentYAxisLabelFormatter } from '@/utils/highcharts-utils';

const SERIES_NAME_COLUMN = 'Portfolio Value';
const SERIES_NAME_MIN_MAX = 'Min/Max';

@Component
export default class PerIntervalPortfolioChart extends Vue {
  chartOptions: Options = {
    chart: {
      height: '300px',
      zoomType: 'x',
    },
    series: [],
    xAxis: {
      type: 'datetime',
      plotLines: [],
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        formatter: currentYAxisLabelFormatter,
      },
    },
    title: {
      text: 'Portfolio Growth Per Quarter',
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      formatter: undefined,
      useHTML: true,
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: {point.y:,.2f}<br>',
    },
  };

  isLoading = false;

  liquidityModule = getModule(LiquidityModule, this.$store);

  @Watch('liquidityInterval', {
    immediate: true,
  })
  onIntervalChange(newInterval: LiquidityInterval): void {
    this.loadData(newInterval, this.liquidityGroupBy, this.liquidityDateRange);
  }

  @Watch('liquidityGroupBy')
  onGroupByChange(groupBy: LiquidityGroupBy): void {
    this.loadData(this.liquidityInterval, groupBy, this.liquidityDateRange);
  }

  @Watch('liquidityDateRange')
  onDateRangeChange(dateRange: [string, string]): void {
    this.loadData(this.liquidityInterval, this.liquidityGroupBy, dateRange);
  }

  get liquidityInterval(): LiquidityInterval {
    return this.liquidityModule.intervalFilter;
  }

  get liquidityGroupBy(): LiquidityGroupBy {
    return this.liquidityModule.groupByFilter;
  }

  get liquidityDateRange(): [string, string] {
    return this.liquidityModule.dateRangeFilter;
  }

  async loadData(
    interval: LiquidityInterval,
    groupBy: LiquidityGroupBy,
    dateRange: [string, string],
  ): Promise<void> {
    try {
      this.isLoading = true;

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const startObject: {
        minMaxData: [number, number | null, number | null][];
        portfolioValueData: [number, number][];
        todaysUnixtime: number | null;
      } = {
        minMaxData: [],
        portfolioValueData: [],
        todaysUnixtime: null,
      };
      const {
        minMaxData,
        portfolioValueData,
        todaysUnixtime,
      } = portfolioPerIntervalResponse(interval).data.portfolioPerInterval.reduce((acc, row) => {
        const unixtime = moment.utc(row.start, 'YYYY-MM-DD HH:mm:ss').valueOf();
        acc.portfolioValueData.push([unixtime, row.value]);

        const rowContainsToday = moment().isBetween(
          moment(row.start, 'YYYY-MM-DD HH:mm:ss'),
          moment(row.end, 'YYYY-MM-DD HH:mm:ss'),
          null,
          '[]',
        );

        if (rowContainsToday) {
          acc.todaysUnixtime = unixtime;
        }

        // don't include min/max data when interval !== 'day'
        // this is because we may only have min/max data for 4/7 days
        // of the week, in which case the mix/max graph will be less than expected
        if (!rowContainsToday
          || interval === 'day'
        ) {
          acc.minMaxData.push([unixtime, row.minimum, row.maximum]);
        }

        return acc;
      }, startObject);

      if (this.chartOptions && this.chartOptions.title && this.chartOptions.title.text) {
        const chartTitle = `Portfolio Growth Per ${interval
          ? interval[0].toUpperCase() + interval.slice(1)
          : ''}`;

        this.chartOptions.title.text = chartTitle;
      }

      if (todaysUnixtime !== null
        && this.chartOptions
        && this.chartOptions.xAxis
        && (this.chartOptions.xAxis as XAxisOptions).plotLines
      ) {
        const plotLineText = getPlotLineText(interval);
        (this.chartOptions.xAxis as XAxisOptions).plotLines = [{
          color: CHART_PLOT_LINE_COLOR,
          width: 2,
          value: todaysUnixtime,
          dashStyle: 'ShortDash',
          label: {
            text: plotLineText,
            style: {
              color: CHART_PLOT_LINE_COLOR,
            },
            rotation: 0,
          },
        }];
      }

      const portfolioValueDataFiltered = portfolioValueData.filter(([date]) => (
        moment(date).isBetween(
          moment(dateRange[0], 'YYYY-MM-DD HH:mm:ss'),
          moment(dateRange[1], 'YYYY-MM-DD HH:mm:ss'),
        )));
      const minMaxDataFiltered = minMaxData.filter(([date]) => (
        moment(date).isBetween(
          moment(dateRange[0], 'YYYY-MM-DD HH:mm:ss'),
          moment(dateRange[1], 'YYYY-MM-DD HH:mm:ss'),
        )));

      switch (groupBy) {
        case 'None':
          if (this.chartOptions && this.chartOptions.series) {
            this.chartOptions.series = [
              {
                type: 'column',
                data: portfolioValueDataFiltered,
                name: SERIES_NAME_COLUMN,
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'columnrange',
                data: minMaxDataFiltered,
                name: SERIES_NAME_MIN_MAX,
                color: CHART_SERIES_COLOR_LIGHT_GREY,
                borderColor: CHART_SERIES_COLOR_LIGHT_GREY,
                maxPointWidth: 1,
              },
            ];
          }

          if (
            this.chartOptions
            && this.chartOptions.tooltip
          ) {
            this.chartOptions.tooltip.formatter = function noGroupByTooltipFormattter() {
              if (!this.points) {
                return '';
              }

              const htmlRows = this.points.reduce((acc, point) => {
                if (point.series.name === SERIES_NAME_MIN_MAX) {
                  const high = Number(point.point.options.high);
                  const low = Number(point.point.options.low);
                  acc.max = `$${numberFormat(high, 2)}`;
                  acc.min = `$${numberFormat(low, 2)}`;
                } else if (point.series.name === SERIES_NAME_COLUMN) {
                  const value = Number(point.y);
                  acc.columnValue = `$${numberFormat(value, 2)}`;
                }

                return acc;
              }, {
                max: '',
                min: '',
                columnValue: '',
              });

              const maxRow = htmlRows.max !== ''
                ? `<tr>
                  <td style="padding-right: 4px;">Max</td><td>${htmlRows.max}</td>
                </tr>`
                : '';
              const minRow = htmlRows.min !== ''
                ? `<tr>
                  <td style="padding-right: 4px;">Min</td><td>${htmlRows.min}</td>
                </tr>`
                : '';
              const formattedDate = dateFormat('%A, %b %e, %Y', this.x);

              return `${formattedDate}<br>
                <table>
                  <tbody>
                    <tr>
                      <td style="padding-right: 4px;"><strong>${SERIES_NAME_COLUMN}</strong></td>
                      <td style="border-bottom: 1px solid black">${htmlRows.columnValue}</td>
                    </tr>
                    ${maxRow}
                    ${minRow}
                  </tbody>
                </table>`;
            };
          }
          if (this.chartOptions && this.chartOptions.legend) {
            this.chartOptions.legend.enabled = false;
          }
          break;
        case 'Exchange':
          if (this.chartOptions && this.chartOptions.series) {
            this.chartOptions.series = [
              {
                type: 'column',
                data: portfolioValueDataFiltered.map(([date, value]) => ([date, value * 1])),
                name: 'NYSE Portfolio Value',
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'column',
                data: portfolioValueDataFiltered.map(([date, value]) => ([date, value * 0])),
                name: 'NASDAQ Portfolio Value',
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
              {
                type: 'column',
                data: portfolioValueDataFiltered.map(([date, value]) => ([date, value * 0])),
                name: 'OTC Portfolio Value',
                color: CHART_SERIES_COLOR_RED,
                borderColor: CHART_SERIES_COLOR_RED,
              },
            ];
          }

          if (
            this.chartOptions
            && this.chartOptions.tooltip
          ) {
            this.chartOptions.tooltip.formatter = undefined;
          }
          if (this.chartOptions && this.chartOptions.legend) {
            this.chartOptions.legend.enabled = true;
          }
          break;
        case 'Sector':
          if (this.chartOptions && this.chartOptions.series) {
            this.chartOptions.series = [
              {
                type: 'column',
                data: portfolioValueDataFiltered.map(([date, value]) => ([date, value * 0.37])),
                name: 'Information Technology Portfolio Value',
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'column',
                data: portfolioValueDataFiltered.map(([date, value]) => ([date, value * 0.63])),
                name: 'Healthcare Portfolio Value',
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
            ];
          }

          if (
            this.chartOptions
            && this.chartOptions.tooltip
          ) {
            this.chartOptions.tooltip.formatter = undefined;
          }
          if (this.chartOptions && this.chartOptions.legend) {
            this.chartOptions.legend.enabled = true;
          }
          break;
        case 'Market Cap':
          if (this.chartOptions && this.chartOptions.series) {
            this.chartOptions.series = [
              {
                type: 'column',
                data: portfolioValueDataFiltered.map(([date, value]) => ([date, value * 0.3])),
                name: 'Micro Portfolio Value',
                color: CHART_SERIES_COLOR_BLUE,
                borderColor: CHART_SERIES_COLOR_BLUE,
              },
              {
                type: 'column',
                data: portfolioValueDataFiltered.map(([date, value]) => ([date, value * 0.7])),
                name: 'Nano Portfolio Value',
                color: CHART_SERIES_COLOR_ORANGE,
                borderColor: CHART_SERIES_COLOR_ORANGE,
              },
            ];
          }

          if (
            this.chartOptions
            && this.chartOptions.tooltip
          ) {
            this.chartOptions.tooltip.formatter = undefined;
          }
          if (this.chartOptions && this.chartOptions.legend) {
            this.chartOptions.legend.enabled = true;
          }
          break;
        default:
          // do nothing
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      this.isLoading = false;
    }
  }
}
