














import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import holdingAssetResponse from '@/api/mockData/holdingAssetResponse';
import { Options } from 'highcharts';
import { CHART_SERIES_COLOR_BLUE } from '@/constants/chart';
import { currentPointFormatter, currentYAxisLabelFormatter } from '@/utils/highcharts-utils';

@Component
export default class HoldingPositionValueChart extends Vue {
  chartOptions: Options = {
    chart: {
      zoomType: 'x',
      height: '200px',
    },
    legend: {
      enabled: false,
    },
    series: [{
      type: 'line',
      data: [],
      name: 'Position Value',
      color: CHART_SERIES_COLOR_BLUE,
      tooltip: {
        pointFormatter: currentPointFormatter,
      },
    }],
    title: {
      text: 'Position Value',
      style: {
        fontSize: '16px',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        formatter: currentYAxisLabelFormatter,
      },
    },
    xAxis: {
      type: 'datetime',
    },
  };

  isLoading = false;

  async loadData(): Promise<void> {
    try {
      this.isLoading = true;

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const startObject: {
        positionValueData: [number, number][],
      } = {
        positionValueData: [],
      };

      const {
        positionValueData,
      } = holdingAssetResponse.data.holdingAsset.reduce((acc, row) => {
        acc.positionValueData.push([
          moment.utc(row.date).valueOf(),
          row.value1,
        ]);

        return acc;
      }, startObject);

      if (this.chartOptions && this.chartOptions.series && this.chartOptions.series[0]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.chartOptions.series[0] as any).data = positionValueData;
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      this.isLoading = false;
    }
  }

  mounted(): void {
    this.loadData();
  }
}
