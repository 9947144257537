import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/views/Login/index.vue';
import Dashboard from '@/views/Dashboard/index.vue';
import FundOverview from '@/views/FundOverview/index.vue';
import Deals from '@/views/Deals/index.vue';
import Liquidity from '@/views/Liquidity/index.vue';
import NotFound from '@/views/NotFound/index.vue';
import {
  ROUTE_NAME_LOGIN,
  ROUTE_NAME_DASHBOARD,
  ROUTE_NAME_FUND_OVERVIEW,
  ROUTE_NAME_NOT_FOUND,
  ROUTE_LIQUIDITY,
  ROUTE_NAME_DEALS,
} from './routes';
import authGuard from './guards/authGuard';
import loginGuard from './guards/loginGuard';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: ROUTE_NAME_LOGIN },
  },
  {
    path: '/login',
    name: ROUTE_NAME_LOGIN,
    component: Login,
    beforeEnter: loginGuard,
  },
  {
    path: '/app',
    name: ROUTE_NAME_DASHBOARD,
    component: Dashboard,
    beforeEnter: authGuard,
    redirect: { name: ROUTE_LIQUIDITY },
    children: [
      {
        path: 'fund-overview',
        name: ROUTE_NAME_FUND_OVERVIEW,
        component: FundOverview,
      },
      {
        path: 'deals',
        name: ROUTE_NAME_DEALS,
        component: Deals,
      },
      {
        path: 'liquidity',
        name: ROUTE_LIQUIDITY,
        component: Liquidity,
      },
    ],
  },
  {
    path: '*',
    name: ROUTE_NAME_NOT_FOUND,
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
