







import Vue from 'vue';
import Component from 'vue-class-component';
import {
  Options,
  numberFormat,
  dateFormat,
  XAxisOptions,
} from 'highcharts';
import {
  CHART_PLOT_LINE_COLOR,
  CHART_SERIES_COLOR_BLUE,
  CHART_SERIES_COLOR_LIGHT_GREY,
} from '@/constants/chart';
import { currentYAxisLabelFormatter } from '@/utils/highcharts-utils';

const SERIES_NAME_MIN_MAX = 'Min/Max';

@Component({
  props: {
    title: {
      type: String,
      required: true,
    },
    lineSeriesLabel: {
      type: String,
      required: true,
    },
    minMaxSeriesLabel: {
      type: String,
      required: false,
      default: SERIES_NAME_MIN_MAX,
    },
  },
})
export default class CumulativeChart extends Vue {
  chartOptions: Options | null = null;

  title!: string;

  lineSeriesLabel!: string;

  minMaxSeriesLabel!: string;

  async mounted(): Promise<void> {
    this.chartOptions = {
      chart: {
        height: '300px',
        zoomType: 'x',
      },
      series: [],
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%Y-%m-%d}',
        },
        plotLines: [],
      },
      yAxis: {
        title: {
          text: '',
        },
        labels: {
          formatter: currentYAxisLabelFormatter,
        },
      },
      title: {
        text: this.title,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        formatter: undefined,
        useHTML: true,
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: {point.y:,.2f}<br>',
      },
    };
    await new Promise((resolve) => setTimeout(resolve, 1000));
    window.dispatchEvent(new Event('resize'));
  }

  // set multi line data
  setMultiLineData(
    ...mutliLineData: {
      name: string;
      data: [number, number][]
    }[]
  ): void {
    // set line & min/max data
    if (this.chartOptions && this.chartOptions.series) {
      this.chartOptions.series = mutliLineData.map((row) => ({
        type: 'line',
        data: row.data,
        name: row.name,
      }));
    }

    if (this.chartOptions && this.chartOptions.tooltip && this.chartOptions.tooltip) {
      this.chartOptions.tooltip.formatter = undefined;
    }
  }

  // set single line & min/max
  setSingleLineData(
    lineData: [number, number][],
    minMaxData: [number, number | null, number | null][],
  ): void {
    // set line & min/max data
    if (this.chartOptions && this.chartOptions.series) {
      this.chartOptions.series = [
        {
          type: 'line',
          data: lineData,
          name: this.lineSeriesLabel,
          color: CHART_SERIES_COLOR_BLUE,
        },
        {
          type: 'columnrange',
          data: minMaxData,
          name: this.minMaxSeriesLabel,
          color: CHART_SERIES_COLOR_LIGHT_GREY,
          borderColor: CHART_SERIES_COLOR_LIGHT_GREY,
          maxPointWidth: 1,
        },
      ];
    }

    if (this.chartOptions && this.chartOptions.tooltip) {
      const {
        minMaxSeriesLabel,
        lineSeriesLabel,
      } = this;
      this.chartOptions.tooltip.formatter = function singleLineToolipFormatter() {
        if (!this.points) {
          return '';
        }

        const htmlRows = this.points.reduce((acc, point) => {
          if (point.series.name === minMaxSeriesLabel) {
            const high = Number(point.point.options.high);
            const low = Number(point.point.options.low);
            acc.max = `$${numberFormat(high, 2)}`;
            acc.min = `$${numberFormat(low, 2)}`;
          } else if (point.series.name === lineSeriesLabel) {
            const value = Number(point.y);
            acc.lineValue = `$${numberFormat(value, 2)}`;
          }

          return acc;
        }, {
          max: '',
          min: '',
          lineValue: '',
        });

        const maxRow = htmlRows.max !== ''
          ? `<tr>
            <td style="padding-right: 4px;">Max</td><td>${htmlRows.max}</td>
          </tr>`
          : '';
        const minRow = htmlRows.min !== ''
          ? `<tr>
            <td style="padding-right: 4px;">Min</td><td>${htmlRows.min}</td>
          </tr>`
          : '';
        const formattedDate = dateFormat('%A, %b %e, %Y', this.x);

        return `${formattedDate}<br>
          <table>
            <tbody>
              <tr>
                <td style="padding-right: 4px;"><strong>${lineSeriesLabel}</strong></td>
                <td style="border-bottom: 1px solid black">${htmlRows.lineValue}</td>
              </tr>
              ${maxRow}
              ${minRow}
            </tbody>
          </table>`;
      };
    }
  }

  setPlotLine(index: number, text: string): void {
    if (this.chartOptions
      && this.chartOptions.xAxis
      && (this.chartOptions.xAxis as XAxisOptions).plotLines
    ) {
      (this.chartOptions.xAxis as XAxisOptions).plotLines = [{
        color: CHART_PLOT_LINE_COLOR,
        width: 2,
        value: index,
        dashStyle: 'ShortDash',
        label: {
          text,
          rotation: 0,
          style: {
            color: CHART_PLOT_LINE_COLOR,
          },
        },
      }];
    }
  }

  setLegend(showLegend: boolean): void {
    if (this.chartOptions
      && this.chartOptions.legend) {
      this.chartOptions.legend.enabled = showLegend;
    }
  }
}
