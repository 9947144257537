import { HoldingAssetResponse } from '@/types/responses';
import AssetData from './csv/srax_holding_asset_data.csv';

const holdingAssetResponse: HoldingAssetResponse = {
  data: {
    holdingAsset: AssetData,
  },
};

export default holdingAssetResponse;
