





























































import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import MyInfoModule from '@/store/modules/myInfo';
import { ROUTE_NAME_DASHBOARD } from '@/router/routes';

@Component
export default class Login extends Vue {
  myInfoModule = getModule(MyInfoModule, this.$store);

  showError = false;

  get loginIsSubmitting(): boolean {
    return this.myInfoModule.tokenDataStatus === 'loading';
  }

  form = {
    email: '',
    password: '',
  }

  async onSubmit(): Promise<void> {
    try {
      await this.myInfoModule.login(this.form.email, this.form.password);
      this.$router.push({
        name: ROUTE_NAME_DASHBOARD,
      });
    } catch (error) {
      this.showError = true;
    }
  }
}
