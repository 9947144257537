import { NavigationGuard } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import MyInfoModule from '@/store/modules/myInfo';
import store from '@/store';
import { ROUTE_NAME_LOGIN } from '@/router/routes';

const guard: NavigationGuard = (to, from, next) => {
  const myInfoModule = getModule(MyInfoModule, store);

  if (!myInfoModule.isLoggedIn) {
    next({ name: ROUTE_NAME_LOGIN });
    return;
  }

  next();
};

export default guard;
