















import { Options } from 'highcharts';
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import holdingMarketPriceResponse from '@/api/mockData/holdingMarketPriceResponse';
import { CHART_SERIES_COLOR_BLUE } from '@/constants/chart';
import { currentYAxisLabelFormatter } from '@/utils/highcharts-utils';

@Component
export default class HoldingMarketPriceChart extends Vue {
  chartOptions: Options = {
    rangeSelector: {
      inputEnabled: false,
    },
    title: {
      text: 'Market Price',
    },
    yAxis: [
      {
        labels: {
          align: 'right',
          x: -3,
          formatter: currentYAxisLabelFormatter,
        },
        title: {
          text: 'Price',
        },
        height: '60%',
        lineWidth: 2,
        resize: {
          enabled: true,
        },
      },
      {
        labels: {
          align: 'right',
          x: -3,
        },
        title: {
          text: 'Volume',
        },
        top: '65%',
        height: '35%',
        offset: 0,
        lineWidth: 2,
      },
    ],
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%Y-%m-%d}',
      },
    },
    tooltip: {
      split: true,
    },
    series: [
      {
        type: 'line',
        name: 'AAAA',
        data: [],
        dataGrouping: {
          units: [],
        },
        color: CHART_SERIES_COLOR_BLUE,
        tooltip: {
          pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: {point.y:,.2f}<br>',
        },
      },
      {
        type: 'column',
        name: 'Volume Self',
        data: [],
        yAxis: 1,
        color: CHART_SERIES_COLOR_BLUE,
        dataGrouping: {
          units: [],
        },
        stack: 'volume',
        stacking: 'normal',
      },
      {
        type: 'column',
        name: 'Volume Other',
        data: [],
        yAxis: 1,
        color: '#ddd',
        dataGrouping: {
          units: [],
        },
        stack: 'volume',
        stacking: 'normal',
      },
    ],
  };

  isLoading = false;

  async loadData(): Promise<void> {
    try {
      this.isLoading = true;

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const startObject: {
        volumeOther: [number, number][];
        volumeSelf: [number, number][];
        closingPrice: [number, number][];
      } = {
        volumeOther: [],
        volumeSelf: [],
        closingPrice: [],
      };

      const {
        volumeOther,
        volumeSelf,
        closingPrice,
      } = holdingMarketPriceResponse.data.holdingMarketPrice.reduce((acc, item) => {
        const unixtime = moment.utc(item.date).valueOf();
        acc.volumeOther.push([
          unixtime,
          item.tradingVolume1 - item.selfVolume1,
        ]);
        acc.volumeSelf.push([
          unixtime,
          item.selfVolume1,
        ]);
        acc.closingPrice.push([unixtime,
          item.closingPrice1,
        ]);

        return acc;
      }, startObject);

      if (this.chartOptions && this.chartOptions.series && this.chartOptions.series[0]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.chartOptions.series[0] as any).data = closingPrice;
      }

      if (this.chartOptions && this.chartOptions.series && this.chartOptions.series[1]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.chartOptions.series[1] as any).data = volumeSelf;
      }

      if (this.chartOptions && this.chartOptions.series && this.chartOptions.series[2]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.chartOptions.series[2] as any).data = volumeOther;
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      this.isLoading = false;
    }
  }

  mounted(): void {
    this.loadData();
  }
}
