import { AxisLabelsFormatterContextObject, numberFormat, Point } from 'highcharts';

export const currentPointFormatter = function currentPointFormatter(this: Point): string {
  if (typeof this.y === 'undefined') {
    return '';
  }

  const isNegative = this.y && this.y < 0;
  const absoluteValue = Math.abs(this.y);

  const formattedValue = `${isNegative ? '-' : ''}$${numberFormat(absoluteValue, 2)}`;

  return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: ${formattedValue}<br>`;
};

export const currentYAxisLabelFormatter = function currentYAxisLabelFormatter(
  this: AxisLabelsFormatterContextObject,
): string {
  if (this.value < 0) {
    this.value = -this.value;
    return `-$${this.axis.defaultLabelFormatter.call(this)}`;
  }
  return `$${this.axis.defaultLabelFormatter.call(this)}`;
};
