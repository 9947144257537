import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators';
import { login } from '@/api';
import { DataStatus } from '@/types/state';
import '@/store/config/moduleConfig';

interface UserObject {
  name: string,
}

@Module({
  namespaced: true,
  name: 'myInfo',
})
export default class MyInfoModule extends VuexModule {
  token: string | null = null;

  tokenDataStatus: DataStatus = 'idle';

  myInfo: UserObject | null = null;

  get isLoggedIn(): boolean {
    return this.token !== null;
  }

  get username(): string | null {
    return this.myInfo && this.myInfo.name ? this.myInfo.name : null;
  }

  @Mutation
  setMyInfo(myInfo: UserObject | null): void {
    this.myInfo = myInfo;
  }

  @Mutation
  setToken(token: string | null): void {
    this.token = token;
  }

  @Mutation
  setTokenDataStatus(status: DataStatus): void {
    this.tokenDataStatus = status;
  }

  /**
   * Login with user credentials
   * @param email
   * @param password
   */
  @Action
  async login(email: string, password: string): Promise<void> {
    try {
      this.setTokenDataStatus('loading');

      const res = await login(email, password);

      this.setToken(res.token);
      this.setMyInfo({ name: res.name });
      this.setTokenDataStatus('idle');
    } catch (error) {
      this.setTokenDataStatus('error');
      throw error;
    }
  }

  /**
   * Logout
   */
  @Action
  logout(): void {
    this.setToken(null);
    this.setMyInfo(null);
  }
}
