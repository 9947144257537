import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import LiquidityModule from './modules/liquidity';
import MyInfoModule from './modules/myInfo';

interface State {
  myInfo: MyInfoModule,
}

const vuexLocal = new VuexPersistence<State>({
  storage: window.localStorage,
  reducer: (state) => ({
    myInfo: {
      token: state.myInfo.token,
      myInfo: state.myInfo.myInfo,
    },
  }),
});

Vue.use(Vuex);

export default new Vuex.Store<State>({
  modules: {
    myInfo: MyInfoModule,
    liquidity: LiquidityModule,
  },
  plugins: [
    vuexLocal.plugin,
  ],
});
