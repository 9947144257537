import Vue from 'vue';
import Highcharts from 'highcharts';
import Stock from 'highcharts/modules/stock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsVue from 'highcharts-vue';
import {
  CHART_BACKGROUND_DARK,
  CHART_SERIES_COLOR_BLUE,
  CHART_SERIES_COLOR_ORANGE,
  CHART_PLOT_LINE_COLOR,
  CHART_SERIES_COLOR_RED,
  CHART_SERIES_COLOR_PURPLE,
  CHART_SERIES_COLOR_YELLOW,
} from '@/constants/chart';

Highcharts.setOptions({
  credits: {
    enabled: false,
  },
  colors: [
    CHART_SERIES_COLOR_BLUE,
    CHART_SERIES_COLOR_ORANGE,
    CHART_PLOT_LINE_COLOR,
    CHART_SERIES_COLOR_RED,
    CHART_SERIES_COLOR_PURPLE,
    CHART_SERIES_COLOR_YELLOW,
  ],
  lang: {
    thousandsSep: ',',
  },
  chart: {
    backgroundColor: CHART_BACKGROUND_DARK,
  },
  title: {
    align: 'left',
    style: {
      color: '#E0E0E3',
    },
  },
  yAxis: {
    gridLineColor: 'rgba(255, 255, 255, 0.1)',
    labels: {
      style: {
        color: '#E0E0E3',
      },
    },
    title: {
      style: {
        color: '#E0E0E3',
      },
    },
    lineColor: 'rgba(244, 244, 227, 0.4)',
  },
  xAxis: {
    labels: {
      style: {
        color: '#E0E0E3',
      },
    },
    lineColor: 'rgba(244, 244, 227, 0.4)',
    tickColor: 'rgba(224, 224, 227, 0.4)',
  },
  legend: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    itemStyle: {
      color: '#E0E0E3',
    },
    itemHoverStyle: {
      color: '#FFF',
    },
    itemHiddenStyle: {
      color: '#606063',
    },
    title: {
      style: {
        color: '#C0C0C0',
      },
    },
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
        ],
        theme: {
          fill: 'transparent',
        },
        symbolFill: '#777777',
      },
    },
  },
});

HighchartsMore(Highcharts);
Stock(Highcharts);
HighchartsExporting(Highcharts);
Vue.use(HighchartsVue);
