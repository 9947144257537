














import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import holdingAssetResponse from '@/api/mockData/holdingAssetResponse';
import { Options } from 'highcharts';
import { CHART_SERIES_COLOR_BLUE, CHART_SERIES_COLOR_RED } from '@/constants/chart';

@Component
export default class HoldingFreeRestrictedChart extends Vue {
  chartOptions: Options = {
    chart: {
      zoomType: 'x',
      height: '200px',
    },
    series: [
      {
        type: 'column',
        data: [],
        name: 'Free',
        color: CHART_SERIES_COLOR_BLUE,
        borderColor: CHART_SERIES_COLOR_BLUE,
      },
      {
        type: 'column',
        data: [],
        name: 'Restricted',
        color: CHART_SERIES_COLOR_RED,
        borderColor: CHART_SERIES_COLOR_RED,
      },
    ],
    title: {
      text: ' Free/Restricted Distribution',
      style: {
        fontSize: '16px',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    xAxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: true,
      pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: {point.y:,.0f}<br>',
    },
  };

  isLoading = false;

  async loadData(): Promise<void> {
    try {
      this.isLoading = true;

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const startObject: {
        freeData: [number, number][],
        restrictedData: [number, number][],
      } = {
        freeData: [],
        restrictedData: [],
      };

      const {
        freeData,
        restrictedData,
      } = holdingAssetResponse.data.holdingAsset.reduce((acc, row) => {
        const unixtime = moment.utc(row.date).valueOf();
        acc.freeData.push([
          unixtime,
          row.freeQty1,
        ]);
        acc.restrictedData.push([
          unixtime,
          row.restrictedQty1,
        ]);

        return acc;
      }, startObject);

      if (this.chartOptions && this.chartOptions.series && this.chartOptions.series[0]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.chartOptions.series[0] as any).data = freeData;
      }
      if (this.chartOptions && this.chartOptions.series && this.chartOptions.series[1]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.chartOptions.series[1] as any).data = restrictedData;
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      this.isLoading = false;
    }
  }

  mounted(): void {
    this.loadData();
  }
}
