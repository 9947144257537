import moment from 'moment';

/**
 * Get integer between two integers. Min/Max inclusive
 */
export const randomIntFromInterval = (min: number, max: number): number => Math.floor(
  Math.random() * (max - min + 1) + min,
);

const regexFloat = /(\d)(?=(\d{3})+\.)/g;

const applyRegex = (val: number, regex: RegExp, dec = 0) => val.toFixed(dec).replace(regex, '$1,');

export const formatMoney = (val: number|null|undefined, dec = 3): string => {
  if (!val) return '$0.'.concat('0'.repeat(dec));
  const sign = (Math.sign(val) < 0) ? '-' : '';
  const newNum = Math.abs(val);
  if (val === 0) return '$0.'.concat('0'.repeat(dec));
  return `${sign}$${applyRegex(newNum, regexFloat, dec)}`;
};

export const formatDate = (val: number | string, separator = '/', yLength = 4): string | number => {
  if (!val) return 'Not available';
  const date = moment.utc(val, ['MM/DD/YYYY', moment.ISO_8601, 'YYYY/MM/DD']);
  // date
  let day: number | string = date.date();
  day = day < 10 ? `0${day}` : day;
  let month: number | string = date.month() + 1;
  month = month < 10 ? `0${month}` : month;
  const year = date.year().toString().substr(-yLength);
  return `${month}${separator}${day}${separator}${year}`;
};
